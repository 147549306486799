import { ChangeEvent, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
//
import { UploadPageStyled } from "./styles";
import supabase from "../../../services/supabase";
import routes from "../../../config/routes";
import { AccessLevel } from "../../../types";
import apiClient from "../../../services/api";
import { getErrorMessage } from "../../../utils/error-handlers";
import { Box } from "@mui/material";

const UploadPage: React.FC = () => {
  const navigate = useNavigate();
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [artworkFile, setArtworkFile] = useState<File | null>(null);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [makePublic, setMakePublic] = useState(true);
  const [uploading, setUploading] = useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setAudioFile(file);
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Safely access the first file
    if (file) {
      setArtworkFile(file);
    } else {
      alert("please up load an image file");
    }
  };

  const handleUpload = async (e: FormEvent<HTMLFormElement>) => {
    // prevent page redirect
    e.preventDefault();

    /* validation */

    if (!title) {
      alert("please enter a title");
      return;
    }

    if (!audioFile) {
      alert("please select an audio file to upload");
      return;
    }

    try {
      setUploading(true);
      const audioFileName = `${Date.now()}-${audioFile.name}`;
      let artworkUrl: string | null = null;

      // get user id for insertion
      const uid = (await supabase.auth.getUser()).data.user?.id;

      if (!uid) {
        throw new Error("user id not found");
      }

      if (artworkFile) {
        const artworkFileName = `${Date.now()}-${artworkFile.name}`;
        // Upload image file to Supabase Storage
        const { error: artworkError } = await supabase.storage
          .from("artwork")
          .upload(artworkFileName, artworkFile);

        if (artworkError) {
          throw artworkError;
        }

        // Construct the public URL for the uploaded file
        const { data: dataArtwork } = supabase.storage
          .from("artwork")
          .getPublicUrl(artworkFileName);

        if (!dataArtwork.publicUrl) {
          throw Error("could not generate public url for artwork file");
        }

        artworkUrl = dataArtwork.publicUrl;
      }

      // Upload audio file to Supabase Storage
      const { error: audioError } = await supabase.storage
        .from("recordings")
        .upload(audioFileName, audioFile);

      if (audioError) {
        throw audioError;
      }

      // Construct the public URL for the uploaded file
      const { data: dataAudio } = supabase.storage
        .from("recordings")
        .getPublicUrl(audioFileName);

      if (!dataAudio.publicUrl) {
        throw new Error("could not generate public url for audio file");
      }

      // Insert file metadata into the database table
      await apiClient.post("/recordings", {
        title,
        description,
        url: dataAudio.publicUrl,
        artwork_url: artworkUrl,
        access_level: makePublic ? AccessLevel.PUBLIC : AccessLevel.PRIVATE
        // user_id: uid // todo: remove this and allow the axios interceptor to add it
      });

      alert("file uploaded and saved successfully!");

      navigate(routes.dashboard);
    } catch (e) {
      alert(getErrorMessage(e));
    } finally {
      setUploading(false);
    }
  };

  return (
    <UploadPageStyled>
      <h2>upload recording</h2>

      <form onSubmit={handleUpload}>
        <Stack
          sx={{
            display: "flex",
            justifyContent: { sm: "space-between" },
            flexDirection: { xs: "column", sm: "row" }
          }}
        >
          {/* left */}
          <Box>
            <label htmlFor="recording-file">recording file</label>
            <br />
            <input
              id="recording-file"
              type="file"
              accept="audio/*"
              onChange={handleFileChange}
              alt="recording file"
              required
            />
            <br />
            <br />
            <input
              type="text"
              placeholder="title"
              value={title}
              required
              onChange={(e) => setTitle(e.target.value)}
            />
            <br />
            <textarea
              placeholder="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>

            <br />
            <label htmlFor="makePublic">make public?</label>
            <input
              id="makePublic"
              type="checkbox"
              checked={makePublic}
              aria-label="publish now"
              onChange={(e) => setMakePublic(e.target.checked)}
            />
          </Box>

          {/* right */}
          <Box sx={{ mt: { xs: 2, sm: 0 } }}>
            <label htmlFor="artworkFile">(optional) artwork image</label>
            <br />
            <input
              id="artworkFile"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
            <br />

            {artworkFile && (
              <div>
                <img
                  src={URL.createObjectURL(artworkFile)}
                  style={{ width: 200, height: 200 }}
                  alt="artwork image for recording"
                />

                <br />

                <button onClick={() => setArtworkFile(null)}>
                  remove artwork image
                </button>
              </div>
            )}
          </Box>
        </Stack>

        <Box mt={2}>
          <button type="submit" disabled={uploading}>
            {uploading ? "uploading..." : "upload"}
          </button>
        </Box>
      </form>
    </UploadPageStyled>
  );
};

export default UploadPage;
