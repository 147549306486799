import { createClient } from "@supabase/supabase-js";
//
import { Database } from "../../types/database.types";

const supabase = createClient<Database>(
  "https://fdggchygsqwfhmhsfinc.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZkZ2djaHlnc3F3ZmhtaHNmaW5jIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzA2MTgxMTYsImV4cCI6MjA0NjE5NDExNn0.VEqZiQQOprt8ulpmywfv6CWCFgR7eoy3VZj26A9v5yk"
);

export default supabase;
