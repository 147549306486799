import { MouseEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//
import routes from "../../../config/routes";
import { AccessLevel } from "../../../types";
import apiClient from "../../../services/api";
import { getErrorMessage } from "../../../utils/error-handlers";
import { Tables } from "../../../types/database.types";

const CollectionsEditPage: React.FC = () => {
  const location = useLocation();
  const collection = location.state as Tables<"collections">;

  const navigate = useNavigate();
  const [title, setTitle] = useState<string>(collection.title || "");
  const [description, setDescription] = useState<string>(
    collection.description || ""
  );
  const [makePublic, setMakePublic] = useState(
    collection.access_level === "public"
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState("");

  const handleDelete = async (e: MouseEvent<HTMLButtonElement>) => {
    console.log(e);
    const shouldDelete = confirm("are you sure?");
    if (shouldDelete) {
      try {
        setError("");
        setLoading(true);
        await apiClient.delete(`/collections/${collection.id}`);
        alert("successfully deleted collection");
        navigate(routes.dashboard);
      } catch (e) {
        const errorMessage = getErrorMessage(e);
        alert(errorMessage);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCreate = async () => {
    try {
      setLoading(true);

      // Insert file metadata into the database table
      await apiClient.put(`/collections/${collection.id}`, {
        title,
        description,
        access_level: makePublic ? AccessLevel.PUBLIC : AccessLevel.PRIVATE
      });

      alert("collection saved successfully!");

      navigate(routes.dashboard);
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      alert(errorMessage);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>edit collection</h2>

      <input
        type="text"
        placeholder="title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <br />
      <textarea
        placeholder="description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      ></textarea>
      <br />
      <label htmlFor="makePublic">make public?</label>
      <input
        id="makePublic"
        type="checkbox"
        checked={makePublic}
        aria-label="make public"
        onChange={(e) => setMakePublic(e.target.checked)}
      />
      <br />
      <br />
      <button onClick={handleCreate} disabled={loading}>
        {loading ? "loading..." : "save"}
      </button>
      <br />
      {error && (
        <div>
          <br />
          <div className="text-red">{error}</div>
          <br />
        </div>
      )}
      <br />
      <a href={routes.dashboard}>back</a>
      <br />
      <br />
      <button onClick={handleDelete} className="text-red">
        delete
      </button>
    </div>
  );
};

export default CollectionsEditPage;
