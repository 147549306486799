import { Navigate, useNavigate } from "react-router-dom";
//
import { useAuth } from "../auth-provider";
import routes from "../../config/routes";
import { useEffect } from "react";

interface ProtectedRouteProps {
  children: JSX.Element;
}

// ProtectedRoute component
export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { session } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (session === null) {
      navigate(routes.login);
    }
  }, [navigate, session]);

  return session ? children : <Navigate to="/login" replace />;
};
