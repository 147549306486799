import { StrictMode } from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import * as Sentry from "@sentry/react";
//
import { router } from "./components/router";
import { store } from "./store";
import { AuthProvider } from "./components/auth-provider";
import RootStyles from "./styles";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

Sentry.init({
  dsn: "https://47924f49469c13f323d35562bf0f8c56@o4507913113829376.ingest.us.sentry.io/4508297732358144",
  integrations: []
});

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <AuthProvider>
      <Provider store={store}>
        <RootStyles>
          <RouterProvider router={router} />
        </RootStyles>
      </Provider>
    </AuthProvider>
  </StrictMode>
);
