import { createBrowserRouter } from "react-router-dom";
import LoginPage from "../../pages/login";
import DashboardPage from "../../pages/dashboard";
import SettingsPage from "../../pages/settings";
import routes from "../../config/routes";
import DiscoverPage from "../../pages/discover";
import { ProtectedRoute } from "../protected-route";
import RecordingsNew from "../../pages/recordings/new";
import AudioStreamer from "../../pages/stream";
import RegisterPage from "../../pages/register";
import RecordingsEditPage from "../../pages/recordings/edit";
import CollectionsNewPage from "../../pages/collections/new";
import CollectionsEditPage from "../../pages/collections/edit";
import HomePage from "../../pages/home";
import PricingPage from "../../pages/pricing";
import LayoutStandard from "../layouts/standard";

export const router = createBrowserRouter([
  // public routes
  {
    path: routes.home,
    element: (
      <LayoutStandard>
        <HomePage />
      </LayoutStandard>
    )
  },
  {
    path: routes.login,
    element: (
      <LayoutStandard>
        <LoginPage />
      </LayoutStandard>
    )
  },
  {
    path: routes.register,
    element: (
      <LayoutStandard>
        <RegisterPage />
      </LayoutStandard>
    )
  },
  {
    path: routes.pricing,
    element: (
      <LayoutStandard>
        <PricingPage />
      </LayoutStandard>
    )
  },
  {
    path: routes.discover,
    element: (
      <LayoutStandard>
        <DiscoverPage />
      </LayoutStandard>
    )
  },

  // private routes
  {
    path: routes.dashboard,
    element: (
      <ProtectedRoute>
        <LayoutStandard>
          <DashboardPage />
        </LayoutStandard>
      </ProtectedRoute>
    )
  },
  // recordings
  {
    path: routes.recordingsNew,
    element: (
      <ProtectedRoute>
        <LayoutStandard>
          <RecordingsNew />
        </LayoutStandard>
      </ProtectedRoute>
    )
  },
  {
    path: routes.recordingsEdit,
    element: (
      <ProtectedRoute>
        <LayoutStandard>
          <RecordingsEditPage />
        </LayoutStandard>
      </ProtectedRoute>
    )
  },

  // streams
  {
    path: routes.stream,
    element: (
      <LayoutStandard>
        <AudioStreamer />
      </LayoutStandard>
    )
  },
  {
    path: routes.streamsDetail,
    element: (
      <LayoutStandard>
        <div>hi finish me</div>
      </LayoutStandard>
    )
  },

  // collections
  {
    path: routes.collectionsNew,
    element: (
      <LayoutStandard>
        <CollectionsNewPage />
      </LayoutStandard>
    )
  },
  {
    path: routes.collectionsEdit,
    element: (
      <LayoutStandard>
        <CollectionsEditPage />
      </LayoutStandard>
    )
  },

  // settings
  {
    path: routes.settings,
    element: (
      <ProtectedRoute>
        <LayoutStandard>
          <SettingsPage />
        </LayoutStandard>
      </ProtectedRoute>
    )
  }
]);
