import styled from "styled-components";

const RootStyles = styled.div`
  /* layout */
  .container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  /* colors */
  .text-red {
    color: red;
  }
`;

export default RootStyles;
