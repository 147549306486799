import React, { useState, useEffect } from "react";
import { io, Socket } from "socket.io-client";
import env from "../../config/env";

interface StreamMetadata {
  id: string;
  title: string;
  startedAt: Date;
  description?: string;
  url: string;
  listenerCount: number;
}

// function getMediaRecorderWithSupportedMimeType(stream: MediaStream) {
//   // List of MIME types to try, in order of preference
//   const mimeTypes = [
//     "audio/webm",
//     "audio/webm;codecs=opus",
//     "audio/ogg;codecs=opus",
//     "audio/mp4"
//   ];

//   // Find the first supported MIME type
//   const supportedMimeType = mimeTypes.find((mimeType) => {
//     try {
//       return MediaRecorder.isTypeSupported(mimeType);
//     } catch (e) {
//       console.error(e);
//       return false;
//     }
//   });

//   if (!supportedMimeType) {
//     throw new Error("No supported MIME types found for MediaRecorder");
//   }

//   return new MediaRecorder(stream, { mimeType: supportedMimeType });
// }

const AudioStreamer: React.FC = () => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const [streamTitle, setStreamTitle] = useState<string>("");
  const [streamURL, setStreamURL] = useState<string>("");
  const [currentListeners, setCurrentListeners] = useState(0);

  useEffect(() => {
    const newSocket = io(env.apiHost);
    setSocket(newSocket);

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        // const recorder = getMediaRecorderWithSupportedMimeType(stream);
        // const recorder = new MediaRecorder(stream, { mimeType: "audio/mp4" }); // does not work on firefox
        const recorder = new MediaRecorder(stream); // works on firefox, safari, chrome
        setMediaRecorder(recorder);
      })
      .catch((err) => {
        console.error("Error accessing microphone:", err);
      });

    return () => {
      newSocket.disconnect();
    };
  }, []);

  const startStreaming = () => {
    if (mediaRecorder && socket) {
      const _streamTitle = prompt("enter a stream title:");
      if (!_streamTitle) {
        alert("stream title is required.");
        return;
      }
      setStreamTitle(_streamTitle);

      const _streamURLPath = prompt(
        "(optional) enter a stream URL path (cannot contain spaces or special characters except for dashes and underscores)"
      );

      socket.emit("start-stream", {
        title: _streamTitle,
        urlPath: _streamURLPath
      });

      socket.on("stream-started", (data: StreamMetadata) => {
        setStreamURL(data.url);
      });

      socket.on("stream-error", (message: string) => {
        console.error("Stream error:", message);
        alert(message);
        stopStreaming();
      });

      socket.on("update-listener-count", (currentListeners: number) => {
        setCurrentListeners(currentListeners);
      });

      mediaRecorder.start(100); // Send data every 100ms

      mediaRecorder.ondataavailable = (event: BlobEvent) => {
        if (event.data && event.data.size > 0) {
          event.data.arrayBuffer().then((buffer) => {
            socket.emit("audio-chunk", buffer);
          });
        }
      };
    }
  };

  const stopStreaming = () => {
    if (mediaRecorder && socket) {
      mediaRecorder.stop();
      socket.emit("stop-stream");
      setStreamTitle("");
      setStreamURL("");
    }
  };

  return (
    <div>
      <h1>stream</h1>

      <button onClick={startStreaming} disabled={!!streamTitle}>
        go live now
      </button>
      <br />
      <br />
      <button onClick={stopStreaming} disabled={!streamTitle}>
        stop streaming
      </button>

      {streamURL && (
        <div>
          <p>
            your stream URL:{" "}
            <a href={streamURL} target="_blank">
              {streamURL}
            </a>
          </p>

          <p>current listeners: {currentListeners}</p>

          <p style={{ fontStyle: "italic" }}>
            keep this webpage open to keep your stream alive. closing the tab
            will result in the stream ending.
          </p>
        </div>
      )}
    </div>
  );
};

export default AudioStreamer;
