import styled from "styled-components";

export const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .site-identity {
    padding-right: 16px;
  }

  #site-title {
    font-size: 1.2rem;
    margin-bottom: 0;
    font-weight: 600;
  }

  #site-subtitle {
    font-style: italic;
  }
`;
