import { configureStore } from "@reduxjs/toolkit";
//
import { streamsSlice } from "./streams/slice";

export const store = configureStore({
  reducer: {
    streams: streamsSlice.reducer
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
