import { Box } from "@mui/material";
import constants from "../../config/constants";

const Footer = () => (
  <Box component="footer" aria-label="footer" pt="20px">
    <p>
      &copy; 2024 4ccessible |{" "}
      <a href={`mailto:${constants.EMAIL_CONTACT}`}>contact us</a>
    </p>
  </Box>
);

export default Footer;
