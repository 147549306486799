const routes = {
  home: "/",
  dashboard: "/dashboard",
  discover: "/discover",

  // auth
  login: "/login",
  register: "/register",
  pricing: "/pricing",

  // settings
  settings: "/settings",

  // stream
  stream: "/stream",
  streamsDetail: "/streams/:id",

  // recordings
  recordingsNew: "/recordings/new",
  recordingsEdit: "/recordings/edit/:id",

  // collections
  collectionsNew: "/collections/new",
  collectionsEdit: "/collections/edit/:id"
};

export default routes;
