import { useEffect, useState } from "react";
import routes from "../../config/routes";
import { Link, useNavigate } from "react-router-dom";
import { Tables } from "../../types/database.types";
import env from "../../config/env";
import { StreamMetadata } from "../../types";
import { getErrorMessage } from "../../utils/error-handlers";
import apiClient from "../../services/api";

/**
 * [ ] Manage streams
 * [ ] Manage library items
 */
function DashboardPage() {
  const navigate = useNavigate();

  // recordings
  const [recordings, setRecordings] = useState<Tables<"recordings">[]>([]);
  const [recordingsError, setRecordingsError] = useState("");
  const [recordingsLoading, setRecordingsLoading] = useState(false);

  // streams
  const [streams, setStreams] = useState<StreamMetadata[]>([]);
  const [streamsError, setStreamsError] = useState("");
  const [streamsLoading, setStreamsLoading] = useState(false);

  // collections
  const [collections, setCollections] = useState<Tables<"collections">[]>([]);
  const [collectionsError, setCollectionsError] = useState("");
  const [collectionsLoading, setCollectionsLoading] = useState(false);

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        setCollectionsLoading(true);
        setCollectionsError("");
        const { data } = await apiClient.get("/collections/mine");
        setCollections(data);
      } catch (e) {
        console.error(e);
        setCollectionsError("there was an error fetching collections");
      } finally {
        setCollectionsLoading(false);
      }
    };

    const fetchRecordings = async () => {
      try {
        setRecordingsLoading(true);
        setRecordingsError("");
        const { data } = await apiClient.get("/recordings/mine");
        setRecordings(data);
      } catch (e) {
        const errorMessage = getErrorMessage(e);
        console.error(errorMessage);
        alert(errorMessage);
        setRecordingsError(errorMessage);
      } finally {
        setRecordingsLoading(false);
      }
    };

    const fetchStreams = async () => {
      try {
        setStreamsLoading(true);
        setStreamsError("");
        const { data } = await apiClient.get("/streams/mine");
        setStreams(data);
      } catch (e) {
        console.error(e);
        setStreamsError("there was an error fetching the streams");
      } finally {
        setStreamsLoading(false);
      }
    };

    fetchCollections();
    fetchRecordings();
    fetchStreams();
  }, []);

  const loadStreamPage = () => {
    navigate(routes.stream);
  };

  const loadNewRecordingPage = () => {
    navigate(routes.recordingsNew);
  };

  const editRecording = (id: number) => {
    navigate(routes.recordingsEdit, {
      state: recordings.find((r) => r.id === id)
    });
  };

  const editCollection = (id: number) => {
    navigate(`${routes.collectionsEdit}/${id}`, {
      state: collections.find((r) => r.id === id)
    });
  };

  return (
    <main>
      <h1>dashboard</h1>

      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <h2>my streams</h2>
          <button onClick={loadStreamPage}>go live now</button>
        </div>
        {streamsLoading ? (
          <div>loading...</div>
        ) : streamsError ? (
          <div>{streamsError}</div>
        ) : streams.length ? (
          <ul>
            {streams.map((as) => (
              <li key={as.title}>
                <Link to={`${env.apiHost}/streams/${as.id}`}>
                  {as.title} ({as.listenerCount} listener
                  {as.listenerCount === 1 ? "" : "s"})
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <i>no streams found</i>
        )}
      </div>

      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <h2>my recordings</h2>
          <button onClick={loadNewRecordingPage}>upload recording</button>
        </div>
        {recordingsLoading ? (
          <div>loading...</div>
        ) : recordingsError ? (
          <div>{recordingsError}</div>
        ) : recordings.length ? (
          <ul>
            {recordings.map((r) => (
              <li key={r.id}>
                <Link to={r.url}>{r.title}</Link>
                <span style={{ marginLeft: 8, marginRight: 8 }}>-</span>
                <button onClick={() => editRecording(r.id)}>edit</button>
              </li>
            ))}
          </ul>
        ) : (
          <i>no streams found</i>
        )}
      </div>

      {/* my collections */}
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <h2>my collections</h2>
          <button onClick={() => navigate(routes.collectionsNew)}>
            create new collection
          </button>
        </div>

        {collectionsLoading ? (
          <div>loading...</div>
        ) : collectionsError ? (
          <div>{collectionsError}</div>
        ) : collections.length ? (
          <ul>
            {collections.map((as) => (
              <li key={as.id}>
                <span style={{ marginRight: 16 }}>{as.title}</span>
                <button onClick={() => editCollection(as.id)}>edit</button>
              </li>
            ))}
          </ul>
        ) : (
          <i>no collections found</i>
        )}
      </div>
    </main>
  );
}

export default DashboardPage;
