import { MouseEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//
import routes from "../../../config/routes";
import { Tables } from "../../../types/database.types";
import apiClient from "../../../services/api";
import { getErrorMessage } from "../../../utils/error-handlers";
import { AccessLevel } from "../../../types";

const RecordingsEditPage: React.FC = () => {
  const location = useLocation();
  const recording = location.state as Tables<"recordings">;

  const navigate = useNavigate();

  const [title, setTitle] = useState<string>(recording.title || "");
  const [description, setDescription] = useState<string>(
    recording.description || ""
  );
  const [makePublic, setMakePublic] = useState<boolean>(
    recording.access_level === AccessLevel.PUBLIC
  );
  const [loading, setLoading] = useState<boolean>(false);

  // const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDelete = async (e: MouseEvent<HTMLButtonElement>) => {
    console.log(e);
    const shouldDelete = confirm("are you sure?");
    if (shouldDelete) {
      try {
        setLoading(true);
        await apiClient.delete(`/recordings/${recording.id}`);
        alert("successfully deleted recording");
        navigate(routes.dashboard);
      } catch (e) {
        const errorMessage = getErrorMessage(e);
        alert(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await apiClient.put(`/recordings/${recording.id}`, {
        title,
        description,
        access_level: makePublic ? AccessLevel.PUBLIC : AccessLevel.PRIVATE,
        url: recording.url
      });
      alert("successfully saved recording");
      navigate(routes.dashboard);
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      alert(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // redirect if no recording is found
  useEffect(() => {
    if (!recording) {
      navigate(routes.dashboard);
    }
  }, []);

  return (
    <div>
      <h2>edit recording</h2>
      <input
        type="text"
        placeholder="title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <br />
      <textarea
        placeholder="description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      ></textarea>
      <br />
      <label htmlFor="makePublic">make public?</label>
      <input
        id="makePublic"
        type="checkbox"
        checked={makePublic}
        aria-label="make public"
        onChange={(e) => setMakePublic(e.target.checked)}
      />
      <br />
      <br />
      <button onClick={handleSave} disabled={loading}>
        {loading ? "loading..." : "save"}
      </button>
      <br />

      <br />
      <a href={routes.dashboard}>back</a>
      <br />
      <br />
      <button onClick={handleDelete} className="text-red">
        delete
      </button>

      {recording.artwork_url && (
        <div>
          <img
            src={recording.artwork_url}
            style={{ width: 200, height: 200 }}
            alt="artwork image for recording"
          />

          {/* <br />

                <button onClick={() => setArtworkFile(null)}>
                  remove artwork image
                </button> */}
        </div>
      )}
    </div>
  );
};

export default RecordingsEditPage;
