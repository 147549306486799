import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
//
import type { RootState } from "../";

interface Stream {
  name: string;
  description: string;
  url: string;
  listenerCountCurrent: number;
  listenerCountAllTime: number;
}

// Define a type for the slice state
interface StreamsState {
  data: Stream[];
}

// Define the initial state using that type
const initialState: StreamsState = {
  data: [
    {
      name: "sight into sound (english)",
      url: "https://api.streamfortheblind.com/streams/english",
      listenerCountAllTime: 1,
      listenerCountCurrent: 1,
      description: "live feed from sight into sound (wrapper)"
    }
  ]
};

export const streamsSlice = createSlice({
  name: "streams",
  initialState,
  reducers: {
    setStreams: (state, action: PayloadAction<Stream[]>) => {
      state.data = action.payload;
    }
  }
});

export const { setStreams } = streamsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectStreams = (state: RootState) => state.streams.data;

export default streamsSlice.reducer;
