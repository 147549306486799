import { Link, useNavigate } from "react-router-dom";
//
import routes from "../../config/routes.ts";
import { useAuth } from "../auth-provider/index.tsx";
import { StyledNav } from "./styles.ts";

const Nav = () => {
  const { session, handleSignOut } = useAuth();
  const navigate = useNavigate();

  const requestLogout = async () => {
    handleSignOut();
    navigate(routes.home);
  };

  return (
    <StyledNav>
      {/* left */}
      <div>
        {/* sight identity */}
        <div className="site-identity">
          <Link to={routes.home}>
            <h1 id="site-title">stream for the blind</h1>
          </Link>
          <div id="site-subtitle">
            the world's most accessible live stream and recording management
            system
          </div>
        </div>
      </div>

      {/* right */}
      <div>
        {session ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8
            }}
          >
            <Link to={routes.dashboard}>dashboard</Link>
            <div>|</div>
            <Link to={routes.discover}>discover</Link>
            <div>|</div>
            <Link to={routes.settings}>settings</Link>
            <div>|</div>
            <button onClick={requestLogout}>logout</button>
          </div>
        ) : (
          <div style={{ gap: "8px", display: "flex" }}>
            <Link to={routes.pricing}>pricing</Link>
            <Link to={routes.discover}>discover</Link>
            <Link to={routes.login}>login</Link>
          </div>
        )}
      </div>
    </StyledNav>
  );
};

export default Nav;
