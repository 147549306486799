import { ReactElement } from "react";
import Footer from "../../footer";
import Nav from "../../nav";
import { Container } from "@mui/material";

const LayoutStandard = ({ children }: { children: ReactElement }) => {
  return (
    <Container maxWidth="lg">
      <Nav />
      <main>{children}</main>
      <Footer />
    </Container>
  );
};

export default LayoutStandard;
