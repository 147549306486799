import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
//
import supabase from "../../services/supabase";
import routes from "../../config/routes";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLoginFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError("");

      const { error } = await supabase.auth.signInWithPassword({
        email,
        password
      });

      if (error) {
        setError(error.message);
        return;
      }

      navigate(routes.dashboard);
    } catch (e) {
      alert(e);
      setError("there was an error logging in, please try again later");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>login</h2>

      <form onSubmit={handleLoginFormSubmit}>
        <input
          type="email"
          placeholder="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <br />
        <input
          type="password"
          placeholder="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <br />

        {loading ? <div>loading...</div> : <button type="submit">login</button>}

        <div>{error}</div>
      </form>
    </div>
  );
};

export default Login;
