/**
 * Extracts a user-friendly error message from various types of error objects.
 *
 * @param error - The error object to extract the message from.
 * @returns A string containing the extracted error message.
 */
export function getErrorMessage(error: unknown): string {
  // Check if the error is null
  if (error == null) {
    return "An unknown error occurred.";
  }

  // Handle if the error is a string
  if (typeof error === "string") {
    return error;
  }

  // Handle standard Error objects
  if (error instanceof Error) {
    return error.message;
  }

  // Handle custom error objects with a message property
  if (hasMessageProperty(error)) {
    return error.message;
  }

  // Handle error objects with a title or error_description property
  if (hasTitleProperty(error)) {
    return error.title;
  }

  // Fallback to a generic error message
  return "An unexpected error occurred.";
}

// Type guards and interfaces

function hasMessageProperty(error: unknown): error is { message: string } {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as { message: unknown }).message === "string"
  );
}

function hasTitleProperty(error: unknown): error is { title: string } {
  return (
    typeof error === "object" &&
    error !== null &&
    "title" in error &&
    typeof (error as { title: unknown }).title === "string"
  );
}
