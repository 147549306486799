import { MouseEvent } from "react";
//
import PricingPageStyled from "./styles";

const PricingPage = () => {
  const handleSignUpButtonClick = (
    _: MouseEvent<HTMLButtonElement>,
    tier: string
  ) => {
    alert("contact us at: streamfortheblind@gmail.com");
    console.log("tier: ", tier);
  };

  return (
    <PricingPageStyled>
      <section className="pricing-section">
        <div className="pricing-card premium">
          <h3>standard plan</h3>
          <p>a toolkit to create, manage and share your live audio</p>
          <div className="features">
            <p>
              <strong>3 hrs</strong> live broadcasting per day
            </p>
            <p>
              <strong>60</strong> file uploads
            </p>
          </div>
          <p className="price">
            $50 <span>per month</span>
          </p>
          <button
            className="cta-button"
            onClick={(e) => handleSignUpButtonClick(e, "standard plan")}
          >
            sign up now
          </button>
          <ul className="benefits">
            <li>simple live streaming</li>
            <li>high-quality recording uploads</li>
            <li>embeddable live player</li>
          </ul>
        </div>

        <div className="pricing-card premium-plus">
          <h3>
            pro plan <span className="popular-badge">popular</span>
          </h3>
          <p>additional features, more control over your audio</p>
          <div className="features">
            <p>
              <strong>12 hrs</strong> live broadcasting per day
            </p>
            <p>
              <strong>120</strong> file uploads
            </p>
          </div>
          <p className="price">
            $100 <span>per month</span>
          </p>
          <button
            className="cta-button green"
            onClick={(e) => handleSignUpButtonClick(e, "pro plan")}
          >
            sign up now
          </button>
          <ul className="benefits">
            <li>includes everything in standard plan</li>
            <li>control access to your channel</li>
            <li>switch audio quality</li>
            <li>24/7 broadcasting</li>
            <li>live stream url</li>
            <li>advanced stats</li>
          </ul>
        </div>

        <div className="pricing-card enterprise">
          <h3>enterprise</h3>
          <p>custom audio solutions and plans for your organization</p>
          <button
            className="cta-button blue"
            onClick={(e) => handleSignUpButtonClick(e, "enterprise plan")}
          >
            inquire now
          </button>
          <ul className="benefits">
            <li>advanced customizations</li>
            <li>multiple channels</li>
            <li>enterprise support and onboarding</li>
            <li>monetization</li>
            <li>advanced access control (SSO)</li>
          </ul>
        </div>
      </section>
    </PricingPageStyled>
  );
};

export default PricingPage;
