import axios from "axios";
//
import env from "../../config/env";
import supabase from "../supabase";

const apiClient = axios.create({
  baseURL: env.apiHost
});

// Add request interceptor to inject auth header
apiClient.interceptors.request.use(
  async (config) => {
    try {
      // Get current session
      const {
        data: { session },
        error
      } = await supabase.auth.getSession();

      if (error) {
        throw error;
      }

      if (session?.access_token) {
        // Add the access token to request headers
        config.headers.Authorization = `Bearer ${session.access_token}`;

        // You can also get the user ID if needed
        const userId = session.user.id;
        // Optionally add user ID to headers or use it as needed
        config.headers["X-User-ID"] = userId;
      }

      return config;
    } catch (error) {
      console.error("Error getting auth session:", error);
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor to handle auth errors
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Handle 401 unauthorized errors
    if (error.response?.status === 401) {
      // You could trigger a sign out or refresh token here
      await supabase.auth.signOut();
      // Redirect to login or handle as needed
    }
    return Promise.reject(error);
  }
);

// Helper function to get current user ID
export const getCurrentUserId = async (): Promise<string | null> => {
  try {
    const {
      data: { session },
      error
    } = await supabase.auth.getSession();

    if (error) {
      throw error;
    }

    return session?.user?.id || null;
  } catch (error) {
    console.error("Error getting user ID:", error);
    return null;
  }
};

export default apiClient;
