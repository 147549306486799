import { useState } from "react";
import { useNavigate } from "react-router-dom";
//
import routes from "../../../config/routes";
import { AccessLevel } from "../../../types";
import apiClient from "../../../services/api";
import { getErrorMessage } from "../../../utils/error-handlers";

const CollectionsNewPage: React.FC = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [makePublic, setMakePublic] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState("");

  const handleEdit = async () => {
    try {
      setLoading(true);

      // Insert file metadata into the database table
      await apiClient.post("/collections", {
        title,
        description,
        access_level: makePublic ? AccessLevel.PUBLIC : AccessLevel.PRIVATE
      });

      alert("collection created successfully!");

      navigate(routes.dashboard);
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      alert(errorMessage);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>create collection</h2>

      <input
        type="text"
        placeholder="title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <br />
      <textarea
        placeholder="description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      ></textarea>
      <br />
      <label htmlFor="makePublic">make public?</label>
      <input
        id="makePublic"
        type="checkbox"
        checked={makePublic}
        aria-label="publish now"
        onChange={(e) => setMakePublic(e.target.checked)}
      />
      <br />
      <br />
      <button onClick={handleEdit} disabled={loading}>
        {loading ? "loading..." : "create"}
      </button>
      <br />
      <br />
      <div>{error}</div>
    </div>
  );
};

export default CollectionsNewPage;
