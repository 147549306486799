import { useAuth } from "../../components/auth-provider";

const SettingsPage = () => {
  const { session } = useAuth();

  const organizationTitle = "";
  const apiKey = "";

  return (
    <div>
      <h1>settings</h1>

      <section id="general">
        <h2>general</h2>
        <div>your email: {session?.user.email}</div>
      </section>

      <section id="organization">
        <h2>organization</h2>
        <div>your organization: {organizationTitle}</div>
        <div>access status: </div>
      </section>

      <section id="developer">
        <h2>developer</h2>
        <div>your api key: {apiKey}</div>
        <button>generate api key</button>
      </section>

      <br />
      <br />

      <br />
    </div>
  );
};

export default SettingsPage;
