import { Link } from "react-router-dom";
//
import { useEffect, useState } from "react";
import { Tables } from "../../types/database.types";
import { HomePageStyled } from "../home/styles";
import env from "../../config/env";
import { StreamMetadata } from "../../types";
import apiClient from "../../services/api";
import { getErrorMessage } from "../../utils/error-handlers";

function HomePage() {
  // recordings
  const [recordings, setRecordings] = useState<Tables<"recordings">[]>([]);
  const [recordingsError, setRecordingsError] = useState("");
  const [recordingsLoading, setRecordingsLoading] = useState(false);

  // streams
  const [streams, setStreams] = useState<StreamMetadata[]>([]);
  const [streamsError, setStreamsError] = useState("");
  const [streamsLoading, setStreamsLoading] = useState(false);

  useEffect(() => {
    const fetchRecordings = async () => {
      try {
        setRecordingsLoading(true);
        setRecordingsError("");
        const { data } = await apiClient.get("/recordings");
        setRecordings(data);
      } catch (e) {
        const errorMessage = getErrorMessage(e);
        setRecordingsError(errorMessage);
        alert(errorMessage);
      } finally {
        setRecordingsLoading(false);
      }
    };

    const fetchStreams = async () => {
      try {
        setStreamsLoading(true);
        setStreamsError("");
        const { data } = await apiClient.get("/streams");
        setStreams(data);
      } catch (e) {
        const errorMessage = getErrorMessage(e);
        setStreamsError(errorMessage);
        alert(errorMessage);
      } finally {
        setStreamsLoading(false);
      }
    };

    fetchRecordings();
    fetchStreams();
  }, []);

  return (
    <HomePageStyled>
      <div>
        <h2>discover live streams</h2>
        {streamsLoading ? (
          <div>loading...</div>
        ) : streamsError ? (
          <div>{streamsError}</div>
        ) : streams.length ? (
          <ul>
            {streams.map((as) => (
              <li key={as.title}>
                <Link to={`${env.apiHost}/streams/${as.id}`}>
                  {as.title} ({as.listenerCount} listener
                  {as.listenerCount === 1 ? "" : "s"})
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <i>no live streams found</i>
        )}
      </div>

      <div>
        <h2>discover recordings</h2>
        {recordingsLoading ? (
          <div>loading...</div>
        ) : recordingsError ? (
          <div>{recordingsError}</div>
        ) : recordings.length ? (
          <ul>
            {recordings.map((r) => (
              <li key={r.id}>
                <Link to={r.url}>{r.title}</Link>
              </li>
            ))}
          </ul>
        ) : (
          <i>no recordings found</i>
        )}
      </div>
    </HomePageStyled>
  );
}

export default HomePage;
