export interface StreamMetadata {
  id: string;
  title: string;
  startedAt: Date;
  description?: string;
  url: string;
  listenerCount: number;
}

export enum AccessLevel {
  PUBLIC = "public",
  PRIVATE = "onlyMe"
}
